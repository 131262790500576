import React from 'react';
// import { Fade } from 'react-awesome-reveal';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

const NGOSection = () => {
    // const navigate = useNavigate()

    return (
        <section
            id="journey"
            className="pt-10"
            style={{ background: '#fff6f4', minHeight: '280px'}}
        >
            {/* <Fade direction="up" cascade damping={0.2} triggerOnce={true}> */}
                <div className="content flex width-1800 equal-width" style={{ flexDirection: 'column', alignItems: 'center', color: 'white', margin: 'auto' }}>
                    <h1 className='font38-bold text-center text-black mb-3'>Empowering Communities, One Child at a Time</h1>
                    <p className="font18-light text-center text-black">
                    Are you an academic institution or NGO championing neurodiverse learners? Join us in transforming lives! <br></br>Contact us for partnerships and bulk access to ReadON.
                    </p>
                    <a
                        href="mailto:talktous@orangeneurosciences.com"
                        className="cursor-pointer"
                    >
                    <button
                        className="bg-[#F58220] hover:bg-[#E07B00] font24-light text-[#0B254C] font-bold text-md py-2 px-6 rounded-md transition duration-300 ease-in-out shadow-md btn btn-xs btn-shadow btn-orange mt-6"
                    >
                        Contact Us
                    </button>
                    </a>
                </div>
            {/* </Fade> */}
        </section>
    );
};

export default NGOSection;
