import React from 'react';
import laptopImage from '../assets/patent_desktop.png';
import Patent_t from '../assets/patent_tablet.png';
import Patent_m from '../assets/patent_mobile.png';
// import { Fade } from 'react-awesome-reveal';

const Patent = () => {
    return (
        // <section className="bg-white flex justify-center items-center">
        <div className="w-full mx-auto">
            {/* <Fade direction="up" cascade damping={0.2} triggerOnce={true}> */}
                <picture>

                    {/* Image for laptop screens */}
                    <source media="(min-width: 1024px)" srcSet={laptopImage} />

                    {/* Image for tablet screens */}
                    <source media="(min-width: 768px)" srcSet={Patent_t} />

                    {/* Image for mobile screens */}
                    <img
                        src={Patent_m}
                        alt="Responsive content"
                        className="w-full h-auto"
                        style={{ marginBottom: '-6px' , marginTop:'-2px'}}
                    />
                </picture>
            {/* </Fade> */}
        </div>
        // </section>
    );
};

export default Patent;
